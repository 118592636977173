import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';

export default () => (
	<Layout>
		<h1>ABOUT PAGE</h1>
		<p>This is the about page.</p>

		<Link to="/">&larr; back to Home</Link>
	</Layout>
);
